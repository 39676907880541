import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Banner from "../components/Banner/Banner"

const Traumatologia = () => {
  const data = useStaticQuery(graphql`
    query {
      traumatologia: file(relativePath: { eq: "traumatologia.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pieplano: file(relativePath: { eq: "pieplano.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      escoliosis: file(relativePath: { eq: "escoliosis.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContextProvider>
      <Layout>
        <SEO title="Traumatología" />
        <div className={classes.root}>
          <Banner content="Traumatología Niños y Adultos" />
          <h3 className={classes.title}>Traumatología</h3>
          <p className={classes.content}>
            La gran frecuencia de lesiones tanto deportivas como las de alta
            energía provocadas por accidentes de todo tipo, hacen que sea una
            patología de consulta y tratamiento diario. Para ello tanto los
            tratamientos médicos como quirúrgicos, aseguran un resultado óptimo
            según corresponda a cada paciente.
          </p>
          <Img
            className={classes.heroImg}
            fluid={data.traumatologia.childImageSharp.fluid}
          />
          <h3 className={classes.title}>Ortopedia Infantil</h3>
          <p className={classes.content}>
            Las patologías ortopédicas en los niños son motivo de preocupación
            en los padres de nuestros pacientes, es por eso que ofrecemos en la
            consulta diaria todas las respuestas, así como las distintas
            alternativas de tratamientos para lograr los mejores resultados
            terapéuticos.
          </p>
          <h4 className={classes.subtitle}>
            Entre las patologías más frecuentes:
          </h4>
          <ul className={classes.list}>
            <li>Pie plano</li>
            <li>Escoliosis</li>
            <li>Deformidades congénitas</li>
            <li>Lesiones traumáticas</li>
          </ul>
          <div className={classes.imageContainer}>
            <Img fluid={data.pieplano.childImageSharp.fluid} />
            <Img fluid={data.escoliosis.childImageSharp.fluid} />
          </div>
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default Traumatologia
